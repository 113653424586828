<template>
  <ion-page class="page scrollable">
    <TopBar />
    <div class="header">
      <h1>Email Settings</h1>
    </div>
    <section class="p-3">
      <div v-for="(val, key) in localEmailSettings" :key="key" class="email-settings hoverable">
        <div v-if="key === 'all' || (key !== 'all' && localEmailSettings.all)">
          <input :id="key" v-model="localEmailSettings[key]" type="checkbox" @change="requestSave" />
          <label hoverable :for="key">{{ prettyEmailSettingKey(key) }}</label>
        </div>
      </div>
    </section>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import TopBar from '@/shared/components/TopBar.vue';

@Options({
  name: 'EmailSettingsPage',
  components: { TopBar },
})
export default class EmailSettingsPage extends Vue {
  head() {
    return {
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex,nofollow',
        },
      ],
    };
  }

  public get token() {
    const router = useRouter();
    return router.currentRoute.value.params.token;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public localEmailSettings: any = {};

  public async save() {
    const payload = { token: this.token, settings: this.localEmailSettings };
    const { updateEmailSettings } = authStore();
    const data = await updateEmailSettings(payload);
    this.localEmailSettings = data;
    toast.show('Settings saved', 'nonative', 'success');
  }

  public requestSave = debounce(this.save, 1000, { trailing: true });

  public prettyEmailSettingKey(key: string) {
    if (key === 'all') return 'Accept emails';
    if (key === 'notif_count') return 'Daily notification roundups';
    if (key === 'ongoing_quests') return 'Event roundups';
    return key.replace(/_/, ' ');
  }

  public async created() {
    try {
      const { fetchEmailSettings } = authStore();
      this.localEmailSettings = await fetchEmailSettings(this.token as string);
    } catch (e: any) {
      if (!e.response) {
        const message = 'Could not reach server. Please try again later.';
        toast.show(message, 'nonative', 'danger');
      } else if (e.response.status === 404) {
        const message = 'User not found';
        toast.show(message, 'nonative', 'danger');
      } else {
        const message = 'Unknown error - please try again later.';
        toast.show(message, 'nonative', 'danger');
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none

.header
  text-align: center
  font-size: 24px

.hoverable:hover
  color: gray
  cursor: pointer

label
  margin-left: 8px
</style>
